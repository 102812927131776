import {
  CircleLinkWidgetType,
  CustomWidgetType,
  DynamicWidgetProps,
  HeroWidgetType,
  StorefrontWidget,
  SummaryBoxesWidgetType,
  WidgetCTAWidgetType
} from 'ecosystem'
import { sharedWidgetsFactory } from 'storefront-modules/widgets'
import { CategoryToolbarWidgetType } from 'storefront-modules/categories/widgets/CategoryToolbarWidget'
import { ProductListWidgetType } from 'storefront-modules/products/widgets/ProductListWidget'
import { ProductListVerticalWidgetType } from 'storefront-modules/products/widgets/ProductListVerticalWidget'
import CustomCategoryToolbarWidget from './CustomCategoryToolbarWidget'
import CustomProductListWidget from './CustomProductListWidget'
import CustomCategoryTreeWidget from './CustomCategoryTreeWidget'
import CustomHeroWidget from './CustomHeroWidget'
import CustomWidgetCTA from './CustomWidgetCTA'
import { CustomContactWidget } from './CustomContactWidget'
import { CustomCircleLinkWidget } from './CustomCircleLinkWidget'
import { CustomWidgetIdHandler } from '#/src/custom/widgets/CustomWidgetId/CustomWidgetIdHandler'
import { CustomSummaryBoxesWidget } from '#/src/custom/widgets/CustomSummaryBoxesWidget'

// declare here the widget extensions names. No need to re declare the overriden shared widgets
type WidgetsExtension = 'CONTACT_FORM'

export const widgetsConfig = (
  widget: StorefrontWidget<unknown>,
  // These can be used to create dynamic widgets
  dynamicProps?: DynamicWidgetProps
) => {
  return sharedWidgetsFactory<WidgetsExtension>(widget, {
    dynamicProps,
    extensions: {
      CONTACT_FORM: <CustomContactWidget />,

      CATEGORY_TREE: (
        <CustomCategoryTreeWidget
          widget={widget as StorefrontWidget<CategoryToolbarWidgetType>}
          {...{ dynamicProps }}
        />
      ),

      CATEGORY_TOOLBAR: (
        <CustomCategoryToolbarWidget
          widget={widget as StorefrontWidget<CategoryToolbarWidgetType>}
          {...{ dynamicProps }}
        />
      ),

      PRODUCT_LIST: (
        <CustomProductListWidget
          type="PRODUCT_LIST"
          widget={widget as StorefrontWidget<ProductListWidgetType>}
          {...{ dynamicProps }}
        />
      ),

      PRODUCT_LIST_1: (
        <CustomProductListWidget
          type="PRODUCT_LIST_1"
          widget={widget as StorefrontWidget<ProductListVerticalWidgetType>}
          {...{ dynamicProps }}
        />
      ),

      CIRCLE_LINK: (
        <CustomCircleLinkWidget
          widget={widget as StorefrontWidget<CircleLinkWidgetType>}
          widgetId={(widget as StorefrontWidget<CircleLinkWidgetType>).widget.name}
        />
      ),

      HERO: (
        <CustomHeroWidget
          widget={widget as StorefrontWidget<HeroWidgetType>}
          widgetId={(widget as StorefrontWidget<HeroWidgetType>).widget.name}
        />
      ),

      WIDGET_CTA: (
        <CustomWidgetCTA
          widget={widget as StorefrontWidget<WidgetCTAWidgetType>}
          widgetId={(widget as StorefrontWidget<WidgetCTAWidgetType>).widget.name}
        />
      ),

      SUMMARY_BOXES_LINK: (
        <CustomSummaryBoxesWidget
          widget={widget as StorefrontWidget<SummaryBoxesWidgetType>}
          widgetId={(widget as StorefrontWidget<SummaryBoxesWidgetType>).widget.name}
        />
      ),

      CUSTOM_WIDGET_ID: (
        <CustomWidgetIdHandler
          widget={widget as StorefrontWidget<CustomWidgetType>}
          widgetId={(widget as StorefrontWidget<CustomWidgetType>).widget.name}
        />
      )
    }
  })
}
